import workerConfig from '../../service-worker/config';

export default {
	prevUrlPlp: 'prevUrlPlp',
	plpGridUpdate: 'grid-update',
	productTileSelector: '.js-product_tile',
	infiniteScrollSelector: '.js-infinite_scroll-placeholder[data-loading-state="unloaded"]',
	infiniteScrollSelectorBtn: '.js-load_next_page[data-loading-state="unloaded"]',
	attributeInfiniteScrollUrl: 'data-grid-url',
	worker: workerConfig
};
