import CarouselsFactory from '../classes/carousels/CarouselsFactory';

class CarouselsMgr {
	init(carouselHTMLElements = []) {
		if (!carouselHTMLElements.length) {
			return;
		}

		for (const carouselHTMLElement of carouselHTMLElements) {
			initCarouselsElement(carouselHTMLElement);
		}
	}
}

/**
 * Initizalizes Carousel Element
 * @param {Object} carouselHTMLElement Carousel HTML Element
 */
function initCarouselsElement(carouselHTMLElement) {
	const settingsJSON = carouselHTMLElement.getAttribute('data-config');
	const type = carouselHTMLElement.getAttribute('data-type');

	if (settingsJSON && type) {
		let settings = null;

		try {
			settings = JSON.parse(settingsJSON);
		} catch (error) {
			settings = {};
		}

		CarouselsFactory.create(
			carouselHTMLElement,
			type,
			settings
		);
	}
}

export default new CarouselsMgr();
