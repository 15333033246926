import FullHeightViewport from '../../components/widgets/fullHeightViewport/fullHeight';
import defaultConfig from '../../configs/widgets/fullHeightViewportDefaults';

class FullHeightViewportMgr {
	static initAll() {
		const fullHeightWidgets = document.querySelectorAll(defaultConfig.widgetSel);
		fullHeightWidgets.forEach((fullHeightWidget) => {
			FullHeightViewportMgr.init(fullHeightWidget);
		});
	}

	static init(fullHeightWidget) {
		const configExtended = {
			...defaultConfig,
			pageWidget: fullHeightWidget
		};
		new FullHeightViewport(configExtended).init();
	}
}

export default FullHeightViewportMgr;
