class FullHeightViewport {
	constructor(config) {
		this.pageWidget = config.pageWidget;
		this.header = document.querySelector(config.headerSel);
		this.allVideos = this.pageWidget.querySelectorAll(config.videoItemSel);
		this.orientation = FullHeightViewport.getOrientation();
	}

	static getOrientation() {
		let orientation = 0;
		if (window.screen.orientation) {
			orientation = window.screen.orientation.type.replace('-primary', '');
		} else {
			orientation = (window.orientation === 0 || window.orientation === 180) ? 'portrait' : 'landscape';
		}
		return orientation;
	}

	init() {
		initMarkup.call(this);
	}

	fullHeightViewport() {
		if (app.page.currentPage !== 'homepage') {
			this.pageWidget.style.height = `${document.documentElement.clientHeight - this.header.offsetHeight}px`;
		}
	}

	orientationHandler() {
		this.fullHeightViewport();
		this.allVideos.forEach(function(videoItem) {
			const videoIframe = videoItem.querySelector('iframe');
			videoIframe.setAttribute('height', document.documentElement.clientHeight);
			videoIframe.setAttribute('width', document.documentElement.clientWidth);
		});
	}
}

function initMarkup() {
	this.fullHeightViewport();
	const $super = this;
	if (navigator.userAgent.match('CriOS')) {
		window.addEventListener('orientationchange', function(event) {
			$super.orientationHandler(event);
		});
	} else {
		window.addEventListener('resize', function(event) {
			const orientation = FullHeightViewport.getOrientation();
			if (orientation !== $super.orientation) {
				$super.orientationHandler(event);
				$super.orientation = orientation;
			}
		});
	}
}

export default FullHeightViewport;
