import FormFieldUtils from 'oneapp/src/utils/FormFieldUtils';

/**
 * CSRF
 */
class CSRF {
	/**
	 * @constructor
	 */
	constructor() {
		this.currentCSRFData = null;
		this.settings = {
			csrfTokenDefaultName: 'csrf_token'
		};
	}

	/**
	 * Retrieve CSRF Token
	 * @param {Function} callback callback
	 * @returns {Promise}
	 */
	getCSRFToken(callback, refreshToken = false) {
		return !refreshToken && this.currentCSRFData
			? Promise.resolve(callback(this.currentCSRFData))
			: fetch(app.urls.csrfGenerateToken, { credentials: 'include' })
				.then((response) => response.json())
				.then((csrfData) => {
					this.currentCSRFData = csrfData;
					this.settings.csrfTokenDefaultName = csrfData.tokenName;

					return callback ? callback(csrfData) : Promise.resolve();
				});
	}

	/**
	 * Populate payload by CSRF Token
	 * @param {FormData|Object} payload
	 * @returns {Promise}
	 */
	populatePayloadByCSRFToken(payload = {}, refreshToken) {
		return this.getCSRFToken((csrfData) => {
			if (csrfData) {
				if (payload instanceof FormData) {
					payload.append(csrfData.tokenName, csrfData.token);
				} else if (Array.isArray(payload)) {
					payload.push({ name: csrfData.tokenName, value: csrfData.token });
				} else if (typeof payload === 'string') {
					payload = payload + '&' + csrfData.tokenName + '=' + csrfData.token;
				} else {
					payload[csrfData.tokenName] = csrfData.token;
				}
			}

			return payload;
		}, refreshToken);
	}

	/**
	 * Populate form by CSRF Token
	 * @param {HTMLFormElement} form Form
	 * @returns {Promise}
	 */
	populateFormByCSRFToken(form, refreshToken = false) {
		const csrfTokenInput = form.querySelector(`input[name="${this.settings.csrfTokenDefaultName}"]`);

		return !refreshToken && csrfTokenInput
			? Promise.resolve()
			: this.getCSRFToken((csrfData) => {
				if (csrfData) {
					if (refreshToken && csrfTokenInput) {
						csrfTokenInput.value = csrfData.token;
					} else {
						const formFieldUtils = new FormFieldUtils();

						formFieldUtils.appendHiddenField(form, {
							name: csrfData.tokenName,
							value: csrfData.token
						});
					}
				}

				return Promise.resolve();
			}, refreshToken);
	}
}

export default new CSRF();
