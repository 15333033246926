import AmazonPayButton from './AmazonPayButton';
import CustomAmazonPayButton from './CustomAmazonPayButton';

class AmazonPayButtonMgr {
	render(htmlElement, config, section) {
		if (app.amazon.pay.buttonType === 'default') {
			new AmazonPayButton(htmlElement, config, section);
		} else {
			new CustomAmazonPayButton(htmlElement, config, section);
		}
	}
}

export default new AmazonPayButtonMgr();
