/**
 * Utils for DOM manipulation
 */
class DOM {
	/**
	 * Replace DOM Element with the given html string
	 */
	replaceWith(element, html) {
		if (element) {
			element.insertAdjacentHTML('afterend', html);
			element.remove();
		}
	}
}

export default new DOM();
