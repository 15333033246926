import CarouselsMgr from 'oneapp/src/managers/CarouselsMgr';
import PriceMgr from 'oneapp/src/classes/price/PriceMgr';
import DOM from 'oneapp/src/utils/DOM';
import PriceBuilder from '../classes/price/Builder';

class Page {
	constructor(id) {
		this.id = id;
	}

	init() {
		CarouselsMgr.init(document.querySelectorAll('.js-carousel'));

		$(document).on('ready recommendations.loaded wishlist.uploaded grid-update price-reinit', () => {
			this.loadPrices();
		});

		$(document).on('reinitSwiper', () => {
			CarouselsMgr.init(document.querySelectorAll('.js-carousel:not(.swiper-initialized)'));
		});

		$(document).on('wishlist.added', (event, wishlistQuantity, doNotToggleBlock, $domElement) => {
			if ($domElement) {
				this.loadPrices($domElement.get(0));
			}
		});

		$(document).on('wishlist.removed', (event, $domElement) => {
			if ($domElement) {
				this.loadPrices($domElement.get(0));
			}
		});
	}

	loadPrices(domElement) {
		const domItem = domElement || document;
		const prices = Array.from(domItem.querySelectorAll('.js-product_tile:not(.js-price_loaded)'));
		const priceBuilder = new PriceBuilder('#productTilePrice');

		if (!prices.length) {
			return;
		}

		const pids = prices.map((price) => price.dataset.itemid);

		PriceMgr.fetchPrices(pids)
			.then((returnedPrices) => {
				prices.forEach((price) => {
					const pid = price.dataset.itemid;

					if (returnedPrices[pid]) {
						const priceBlock = priceBuilder.build(returnedPrices[pid]);

						DOM.replaceWith(price.querySelector('.js-product_min_price'), priceBlock);
						price.classList.add('js-price_loaded');
					}
				});
			});
	}
}

export default Page;
