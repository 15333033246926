export default {
	preCacheNextPagesMessage: 'SW_PRECACHE_NEXT_PAGES',
	preCacheBackMessage: 'SW_PRECACHE_BACK',
	emptyCacheMessage: 'SW_EMPTY_CACHE',
	cache: {
		sfHeaderName: 'x-sf-cc-swcache',
		plp: 'api-cache',
		price: {
			name: 'price-cache',
			maxAge: 3600
		},
		expiration: {
			maxAgeSeconds: 1800,
			maxEntries: 20
		},
		statuses: [200]
	},
	partialLoginUrl: 'Login-Logout',
	partialLogoutUrl: 'Account-LoginIframe',
	partialRegistrationUrl: 'registration=true',
	partialSocialLoginUrl: 'OAuthLogin',
	partialGetPriceUrl: 'Product-GetPrices'
};
