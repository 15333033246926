import FullHeightViewportMgr from './widgets/fullHeightViewportMgr';

class WidgetsMgr {
	static init() {
		/** here we must init all others widgets */
		FullHeightViewportMgr.initAll();
	}
}

export default WidgetsMgr;
