import Swiper from './Swiper';
import SwiperAccordion from './SwiperAccordion';
import SwiperHoverable from './SwiperHoverable';
import SwiperWithMenu from './SwiperWithMenu';
import SwiperZoom from './SwiperZoom';

class CarouselsFactory {
	create(carouselHTMLElement, type, config) {
		switch (type) {
			case 'swiper':
				return new Swiper(carouselHTMLElement, config);
			case 'swiperAccordion':
				return new SwiperAccordion(carouselHTMLElement, config);
			case 'swiperHoverable':
				return new SwiperHoverable(carouselHTMLElement, config);
			case 'swiperWithMenu':
				return new SwiperWithMenu(carouselHTMLElement, config);
			case 'swiperZoom':
				return new SwiperZoom(carouselHTMLElement, config);
			default:
				console.log(`Adapter for type: '${type}' not found!`);
		}
	}
}

export default new CarouselsFactory();
