import Swiper from './Swiper';

class SwiperWithMenu {
	constructor(carouselHTMLElement, config) {
		if (config.menu) {
			const items = config.menu.items;

			delete config.menu;

			config.pagination = {
				el: '.swiper-pagination',
				clickable: true,
				renderBullet: function(index, className) {
					if (typeof items[index] === 'undefined') {
						className = 'h-hidden';
					}

					return '<span class="' + className + '">' + (items[index]) + '</span>';
				}
			};

			config.on = {
				slideChange: function() {
					const numberOfSlides = this.slides.length - 1;
					const currentSlide = this.realIndex;

					if (numberOfSlides === currentSlide) {
						document.querySelector('.swiper-pagination').classList.add('h-hidden');
					} else {
						document.querySelector('.swiper-pagination').classList.remove('h-hidden');
					}
				}
			};
		}

		return new Swiper(carouselHTMLElement, config);
	}
}

export default SwiperWithMenu;
