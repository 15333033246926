import Swiper from './Swiper';

class SwiperAccordion {
	constructor(carouselHTMLElement, config) {
		config.buildingCarouselCallback = addSwiperAccordionDecoration;
		const swiper = new Swiper(carouselHTMLElement, config);

		this.init(swiper);

		return swiper;
	}

	/* eslint-disable-next-line */
	init(swiperContext) {
		processAccordionCarousel.call(swiperContext);
	}
}

/**
 * Adds Swiper Accordion Decaortion for carousel
 * @param {Object} carouselHTMLElement Carousel HTML Element
 */
function addSwiperAccordionDecoration(carouselHTMLElement) {
	carouselHTMLElement.classList.add('b-accordion-slider');
	const carouselWrapper = carouselHTMLElement.querySelector('.js-carousel-wrapper');

	if (carouselWrapper) {
		carouselWrapper.classList.add('js-swiper-accordion-carousel-wrapper');
		const carouselSlides = carouselHTMLElement.querySelectorAll('.js-carousel-slide');

		/* eslint-disable-next-line */
		for (const carouselSlide of carouselSlides) {
			carouselSlide.classList.add('js-swiper-accordion-carousel-bar', 'b-accordion-slider_bar');

			if (carouselSlide.classList.contains('js-primary_image')) {
				carouselSlide.classList.add('m-active-bar');
			}
		}
	}
}

function processAccordionCarousel() {
	const getBarsNumber = (accordionCarousel) => (
		accordionCarousel.querySelectorAll('.js-swiper-accordion-carousel-bar').length
	);

	this.custom.carouselHTMLElement.style.setProperty(
		'--bars-qty',
		getBarsNumber(this.custom.carouselHTMLElement)
	);
	initAccordionCarouselEvents.call(this);
}

function initAccordionCarouselEvents() {
	const swiperAccordionSliderBars = this.custom.carouselHTMLElement.querySelectorAll('.js-swiper-accordion-carousel-bar');

	/* eslint-disable-next-line */
	for (const swiperAccordionSliderBar of swiperAccordionSliderBars) {
		swiperAccordionSliderBar.addEventListener('mouseenter', () => {
			if (swiperAccordionSliderBar.classList.contains('m-active-bar')) {
				return;
			}

			swiperAccordionSliderBar
				.closest('.js-swiper-accordion-carousel-wrapper')
				.classList.add('m-hovered-bar');
		});

		swiperAccordionSliderBar.addEventListener('mouseleave', () => {
			swiperAccordionSliderBar
				.closest('.js-swiper-accordion-carousel-wrapper')
				.classList.remove('m-hovered-bar');
		});

		swiperAccordionSliderBar.addEventListener('click', () => {
			if (swiperAccordionSliderBar.classList.contains('m-active-bar')) {
				return;
			}

			const wrapper = swiperAccordionSliderBar.closest('.js-swiper-accordion-carousel-wrapper');
			const activeBar = wrapper.querySelector('.m-active-bar');

			if (activeBar) {
				activeBar.classList.remove('m-active-bar', 'js-primary_image');
			}

			swiperAccordionSliderBar.addEventListener('transitionend', () => {
				swiperAccordionSliderBar.classList.add('js-primary_image');
			}, { once: true });

			swiperAccordionSliderBar.classList.add('m-active-bar');
			wrapper.classList.remove('m-hovered-bar');
		});
	}
}

export default SwiperAccordion;
