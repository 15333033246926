/* global amazon */

/**
 * This crutch is added due to the fact that Amazon Pay script doesn't have any event
 * we can subscribe to, to get notification that Amazon Pay script is loaded and
 * initialized. So, we try to init button within next 5 seconds.
 */
let countOfReinitCalls = 0;

/**
 * Represents AmazonPayButton
 * https://amazonpaycheckoutintegrationguide.s3.amazonaws.com/amazon-pay-checkout/add-the-amazon-pay-button.html#4-render-the-button
 * @constructor
 * @param {HTMLElement} htmlElement Amazon Pay button HTML element
 * @param {Object} config Configuration for Amazon Pay button initialization
 * @param {String} section Section we initialize button from. Check "placement" attribute description for supported values
 */
class AmazonPayButton {
	constructor(htmlElement, config = {}, section) {
		initButton(htmlElement, config, section);
	}
}

function initButton(htmlElement, config, section) {
	if (typeof amazon !== 'undefined') {
		amazon.Pay.renderButton(app.util.generateUniqueQuerySelector(htmlElement), {
			merchantId: config.merchantId,
			ledgerCurrency: config.ledgerCurrency,
			sandbox: config.sandboxMode,
			checkoutLanguage: config.language,
			productType: config.productType,
			placement: section,
			createCheckoutSessionConfig: {
				payloadJSON: config.payload,
				signature: config.signature,
				publicKeyId: config.publicKeyId,
				algorithm: 'AMZN-PAY-RSASSA-PSS-V2'
			}
		});
	} else if (countOfReinitCalls < 10) {
		setTimeout(() => {
			countOfReinitCalls++;
			initButton(htmlElement, config, section);
		}, 500);
	}
}

export default AmazonPayButton;
