import Swiper from './Swiper';

const $cache = {
	document: $(document)
};

/**
 * Represents SwiperZoom
 * @constructor
 * @param {HTMLElement} - carousel HTML element
 * @param {Object} - config for Swiper zoom carousel
 */
class SwiperZoom {
	constructor(carouselHTMLElement, config) {
		const swiper = new Swiper(carouselHTMLElement, config);

		this.init(swiper);

		return swiper;
	}

	/**
	 * Inits Swiper zoom carousel functionality
	 * @param {Object} - initialized Swiper instance
	 */
	/* eslint-disable-next-line */
	init(swiperContext) {
		initSwiperZoomEvents.call(swiperContext);
	}
}

/**
 * @function
 * @description inits Swiper Zoom events
 */
function initSwiperZoomEvents() {
	const swiperInstance = this;

	$cache.document.on('zoomActive', (e, data) => {
		if (typeof data.activeImageIndex === 'number') {
			swiperInstance.slideTo(data.activeImageIndex, 0);
		}
	});

	swiperInstance.on('slideChange', (swiper) => {
		$cache.document.trigger('zoomImageChanged', { activeImageIndex: swiper.realIndex });
	});
}

export default SwiperZoom;
