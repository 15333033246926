class ProgressiveWidthDistributor {
	constructor({ increaseWidthCoefficient, decreaseWidthCoefficient, type } = {}) {
		this.activeElement = null;
		this.cachedActiveElementInitialWidth = 0;
		this.cachedCalculatedActiveElementWidth = 0;
		this.activeElementSiblings = {};
		this.type = type;
		this.increaseWidthCoefficient = increaseWidthCoefficient;
		this.decreaseWidthCoefficient = decreaseWidthCoefficient;
		this.maxNumberOfSlidesOnLanding = 4;
	}

	/**
	* @description processes progressive width calculation
	* @param {HTMLElement} activeElement - active HTML element
	*/
	processWidthDistribution(activeElement) {
		this.activeElement = activeElement;

		this.setActiveElementWidth();
		this.activeElementSiblings = this.getActiveElementSiblings(this.activeElement);

		if (this.activeElementSiblings.before.length && this.activeElementSiblings.after.length) {
			this.setActiveElementSiblingsWidth(this.activeElementSiblings.before.reverse(), this.cachedCalculatedActiveElementWidth);
			this.setActiveElementSiblingsWidth(this.activeElementSiblings.after, this.cachedCalculatedActiveElementWidth);
		} else {
			const siblings = this.activeElementSiblings.before.length ? this.activeElementSiblings.before.reverse() : this.activeElementSiblings.after;

			if (!this.activeElementSiblings.after.length && this.activeElementSiblings.previousSwiperSlide) {
				this.handleLastSlideOffset(this.activeElementSiblings.previousSwiperSlide);
			}

			if (!this.activeElementSiblings.after.length && this.activeElementSiblings.quantity === this.maxNumberOfSlidesOnLanding) {
				this.processMaxNumberOfSlidesOnLanding();
			}

			this.setActiveElementSiblingsWidth(siblings, this.cachedCalculatedActiveElementWidth);
		}
	}

	processMaxNumberOfSlidesOnLanding() {
		const nextActiveElementSibling = this.activeElement.nextElementSibling;

		if (!nextActiveElementSibling || nextActiveElementSibling.classList.contains('h-hidden')) {
			this.activeElement.classList.add('m-last-slide-offset');
		}
	}

	handleLastSlideOffset(previousSwiperSlide) {
		const offset = (this.cachedCalculatedActiveElementWidth - this.cachedActiveElementInitialWidth - (window.innerWidth * 0.0535));

		previousSwiperSlide.setAttribute('style', `width: ${previousSwiperSlide.offsetWidth + offset}px`);
	}

	/**
	* @description sets active element siblings width with progressive decrease
	* @param {Object} siblings
	* @param {Number} startWidth
	*/
	setActiveElementSiblingsWidth(siblings, startWidth) {
		let startProgressiveDecreaseWidth = startWidth;

		/* eslint-disable-next-line */
		for (const sibling of siblings) {
			startProgressiveDecreaseWidth -= startProgressiveDecreaseWidth * this.decreaseWidthCoefficient;
			sibling.setAttribute('style', `width: ${startProgressiveDecreaseWidth}px`);
			sibling.style.setProperty('--element-width', `${startProgressiveDecreaseWidth}px`);
		}
	}

	/**
	* @description sets active element width according to increase coefficient
	*/
	setActiveElementWidth() {
		if (!this.cachedCalculatedActiveElementWidth) {
			this.cachedCalculatedActiveElementWidth = this.activeElement.offsetWidth * this.increaseWidthCoefficient + this.activeElement.offsetWidth;
			this.cachedActiveElementInitialWidth = this.activeElement.offsetWidth;
		}

		this.activeElement.setAttribute('style', `width: ${this.cachedCalculatedActiveElementWidth}px`);
		this.activeElement.style.setProperty('--active-element-width', `${this.cachedCalculatedActiveElementWidth}px`);
	}

	/**
	* @description gets siblings of active element in both directions for hoverable carousel
	* @param {HTMLElement} activeElement - active HTML element
	*/
	getHoverableCarouselElementSiblings(activeElement) {
		const siblings = {};
		const slides = [...activeElement.parentElement.children];
		let filteredSlides = [];

		filteredSlides = slides.filter((elem) => (!elem.classList.contains('h-hidden') && elem.classList.contains('swiper-slide-visible')) || elem === activeElement);
		const idx = filteredSlides.indexOf(activeElement);

		siblings.before = filteredSlides.slice(0, idx);
		siblings.after = filteredSlides.slice(idx + 1);
		siblings.quantity = filteredSlides.length;

		if (!siblings.after.length && slides.length - 1 === slides.indexOf(activeElement)) {
			siblings.previousSwiperSlide = slides.find((element) => element.classList.contains('swiper-slide-prev'));
		}

		if (!siblings.after.length && filteredSlides.length > 3 && filteredSlides.length - 1 === filteredSlides.indexOf(activeElement)) {
			siblings.previousSwiperSlide = filteredSlides[0].previousElementSibling ? filteredSlides[0].previousElementSibling : filteredSlides[0];
		}

		return siblings;
	}

	/**
	* @description gets siblings of active element in both directions
	* @param {HTMLElement} activeElement - active HTML element
	*/
	getAbstractElementSiblings(activeElement) {
		const siblings = {};
		const abstractElements = [...activeElement.parentElement.children];
		const idx = abstractElements.indexOf(activeElement);

		siblings.before = abstractElements.slice(0, idx);
		siblings.after = abstractElements.slice(idx + 1);

		return siblings;
	}

	getActiveElementSiblings(activeElement) {
		switch (this.type) {
			case 'hoverableCarousel':
				return this.getHoverableCarouselElementSiblings(activeElement);
			default:
				return this.getAbstractElementSiblings(activeElement);
		}
	}

	setWidthToDefaultState() {
		if (!this.activeElement) {
			return;
		}

		const elements = this.activeElement.parentElement.children;

		/* eslint-disable-next-line */
		for (const element of elements) {
			element.removeAttribute('style');
		}
	}

	initEvents() {
		document.addEventListener('resize', () => {
			this.cachedCalculatedActiveElementWidth = 0;
			this.cachedActiveElementInitialWidth = 0;
		});
	}
}

export default ProgressiveWidthDistributor;
