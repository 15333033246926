import Swiper from './Swiper';
import ProgressiveWidthDistributor from '../../components/ProgressiveWidthDistributor';

const cache = {
	clickDisableClass: 'h-click-disable',
	mActiveClass: 'm-active',
	hHiddenClass: 'h-hidden',
	mFilterOpenedClass: 'm-filter-opened',
	slideFilterClass: 'js-slide-filter',
	slideFilterActiveClass: 'js-slide-filter-active',
	slideFilterActiveSel: '.js-slide-filter-active',
	slideAnchorClass: 'js-slide-anchor',
	slideAnchorActiveClass: 'js-slide-anchor-active',
	slideAnchorActiveSel: '.js-slide-anchor-active',
	carouselWrapperSel: '.js-carousel-wrapper',
	carouselSlideSel: '.js-carousel-slide',
	hoverableSlideSel: '.js-hoverable-carousel-slide',
	hoverableCarouselArrowSel: '.js-hoverable-carousel-arrow',
	hoverablePreviousArrowClass: 'js-hoverable-prev-arrow',
	hoverableCarouselListWrapperClass: 'b-module_hov-carousel-list-wrapper',
	hoverableCarouselItemClass: 'b-module_hov-carousel-item',
	hoverableCarouselListClass: 'b-module_hov-carousel-list',
	hoverableCarouselSlideClass: 'js-hoverable-carousel-slide',
	mLastSlideOffset: 'm-last-slide-offset',
	numberOfNextSlides: 4,
	slideTransitionSpeedMs: 300,
	timeToEnableHoverSlideEffectMs: 700
};

class SwiperHoverable {
	constructor(carouselHTMLElement, config) {
		config.buildingCarouselCallback = addSwiperAccordionDecoration;
		const swiper = new Swiper(carouselHTMLElement, config);

		this.init(swiper);

		return swiper;
	}

	/* eslint-disable-next-line */
	init(swiperContext) {
		processHoverableCarousel.call(swiperContext);
	}
}

/**
 * Adds Swiper Hoverable Decoration for carousel
 * @param {Object} carouselHTMLElement Carousel HTML Element
 */
function addSwiperAccordionDecoration(carouselHTMLElement) {
	carouselHTMLElement.classList.add(cache.hoverableCarouselListWrapperClass);
	const carouselWrapper = carouselHTMLElement.querySelector(cache.carouselWrapperSel);

	if (carouselWrapper) {
		carouselWrapper.classList.add(cache.hoverableCarouselListClass);
		const carouselSlides = carouselHTMLElement.querySelectorAll(cache.carouselSlideSel);

		/* eslint-disable-next-line */
		for (const carouselSlide of carouselSlides) {
			carouselSlide.classList.add(cache.hoverableCarouselSlideClass, cache.hoverableCarouselItemClass);
		}
	}
}

function processHoverableCarousel() {
	if (!app.device.isMobileView()) {
		this.widthDistributor = new ProgressiveWidthDistributor({ increaseWidthCoefficient: 0.533, decreaseWidthCoefficient: 0.333, type: 'hoverableCarousel' });
		initHoverableWidthEvents.call(this);
	}

	if (this.custom.config.filterableSlides) {
		initFilterableSlidesEvents.call(this);
	}

	if (this.custom.config.anchorableSlides) {
		initAnchorableSlidesEvents.call(this);
	}
}

/**
* @function
* @description inits events for hoverable width functionality
*/
function initHoverableWidthEvents() {
	const navigationArrow = document.querySelector(cache.hoverableCarouselArrowSel);
	const hoverableSlides = this.custom.carouselHTMLElement.querySelectorAll(cache.hoverableSlideSel);
	const isTablet = app.device.isTabletUserAgent();

	if (!isTablet || this.custom.config.anchorableSlides) {
		/* eslint-disable-next-line */
		for (const hoverableSlide of hoverableSlides) {
			hoverableSlide.addEventListener('mouseenter', () => {
				hoverableSlide.classList.add(cache.mActiveClass);
				this.widthDistributor.processWidthDistribution(hoverableSlide);
			});

			hoverableSlide.addEventListener('mouseleave', () => {
				hoverableSlide.classList.remove(cache.mActiveClass, cache.mLastSlideOffset);
				this.widthDistributor.setWidthToDefaultState();
			});
		}
	}

	navigationArrow.addEventListener('click', (e) => {
		const direction = e.target.classList.contains(cache.hoverablePreviousArrowClass) ? 'prev' : 'next';
		const slide = direction === 'prev' ? this.custom.carouselHTMLElement.swiper.activeIndex - cache.numberOfNextSlides : this.custom.carouselHTMLElement.swiper.activeIndex + cache.numberOfNextSlides;

		this.custom.carouselHTMLElement.swiper.slideTo(slide, cache.slideTransitionSpeedMs);
		this.custom.carouselHTMLElement.swiper.update();
		toggleArrowVisibility.call(this, navigationArrow);
	});

	this.custom.carouselHTMLElement.swiper.on('transitionStart', () => {
		if (!isTablet) {
			this.custom.carouselHTMLElement.swiper.$wrapperEl[0].classList.add(cache.clickDisableClass);
			this.widthDistributor.setWidthToDefaultState();
		}
	});

	this.custom.carouselHTMLElement.swiper.on('transitionEnd', () => {
		if (!isTablet) {
			setTimeout(() => {
				this.custom.carouselHTMLElement.swiper.$wrapperEl[0].classList.remove(cache.clickDisableClass);
			}, cache.timeToEnableHoverSlideEffectMs);
		}

		this.widthDistributor.setWidthToDefaultState();
		toggleArrowVisibility.call(this, navigationArrow);

		if (this.custom.config.anchorableSlides) {
			switchAnchorOnTransitionEnd.call(this);
		}
	});

	toggleArrowVisibility.call(this, navigationArrow);
}

/**
* @function
* @description switch anchor event handler on swiper transition end event
*/
function switchAnchorOnTransitionEnd() {
	if (!this.custom.carouselHTMLElement.swiper.isEnd) {
		const slide = this.custom.carouselHTMLElement.swiper.slides[this.custom.carouselHTMLElement.swiper.activeIndex];
		const index = slide.getAttribute('data-id-year');

		this.anchorTitle.innerText = slide.getAttribute('data-id-year');
		this.anchorsContainer.querySelector(cache.slideAnchorActiveSel).classList.remove(cache.slideAnchorActiveClass, cache.mActiveClass);
		this.anchorsContainer.querySelector(`[data-target-year="${index}"]`).classList.add(cache.slideAnchorActiveClass, cache.mActiveClass);
	}
}

/**
* @function
* @description inits events for filter functionality for carousel
*/
function initFilterableSlidesEvents() {
	this.filtersContainer = document.querySelector(`[data-tab-carousel-target=${this.custom.carouselHTMLElement.getAttribute('data-tab-carousel-id')}]`);
	this.filtersContainer.addEventListener('click', switchFilter.bind(this));
}

function initAnchorableSlidesEvents() {
	this.anchorsContainer = document.querySelector(`[data-tab-carousel-target=${this.custom.carouselHTMLElement.getAttribute('data-tab-carousel-id')}]`);
	this.anchorTitle = document.querySelector(`[data-anchor-title=${this.custom.carouselHTMLElement.getAttribute('data-tab-carousel-id')}]`);
	this.anchorsContainer.addEventListener('click', switchAnchor.bind(this));
}

/**
* @function
* @description switch anchor event handler
*/
function switchAnchor(e) {
	if (!e.target.classList.contains(cache.slideAnchorClass)) {
		return;
	}

	this.anchorsContainer.querySelector(cache.slideAnchorActiveSel).classList.remove(cache.slideAnchorActiveClass, cache.mActiveClass);
	e.target.classList.add(cache.slideAnchorActiveClass, cache.mActiveClass);

	const year = e.target.getAttribute('data-target-year');
	const slide = [...this.custom.carouselHTMLElement.swiper.slides].find((element) => element.getAttribute('data-id-year') === year);

	this.custom.carouselHTMLElement.swiper.slideTo(slide.getAttribute('data-index'), cache.slideTransitionSpeedMs);
	this.anchorTitle.innerText = year;
}

/**
* @function
* @description processes slides visibility based on active filter
*/
function switchFilter(e) {
	if (!e.target.classList.contains(cache.slideFilterClass)) {
		return;
	}

	const isMobile = app.device.isMobileView();
	const hasActiveClass = e.target.classList.contains(cache.slideFilterActiveClass);

	if (!hasActiveClass || isMobile) {
		if (hasActiveClass) {
			toggleExpandableFilterClass();

			return;
		}

		const slides = this.custom.carouselHTMLElement.swiper.slides;
		const filterId = e.target.getAttribute('data-filter-id');
		const isShowAll = filterId === 'all';

		/* eslint-disable-next-line */
		for (const slide of slides) {
			if (isShowAll) {
				slide.classList.remove(cache.hHiddenClass);
			} else {
				slide.classList.toggle(cache.hHiddenClass, filterId !== slide.getAttribute('data-filter-id'));
			}
		}

		this.filtersContainer.querySelector(cache.slideFilterActiveSel).classList.remove(cache.slideFilterActiveClass, cache.mActiveClass);
		e.target.classList.add(cache.slideFilterActiveClass, cache.mActiveClass);
		this.custom.carouselHTMLElement.swiper.update();
		this.custom.carouselHTMLElement.swiper.setProgress(0);

		if (!isMobile) {
			toggleArrowVisibility.call(this, document.querySelector(cache.hoverableCarouselArrowSel));
		} else {
			toggleExpandableFilterClass();
		}
	}
}

function toggleExpandableFilterClass() {
	document.body.classList.toggle(cache.mFilterOpenedClass);
}

/**
* @function
* @description toggles navigation arrow visibility
* @param {HTMLElement} arrowContainer
*/
function toggleArrowVisibility(arrowContainer) {
	const [prevArrow, nextArrow] = arrowContainer.children;

	prevArrow.classList.toggle(cache.hHiddenClass, this.custom.carouselHTMLElement.swiper.isBeginning);
	nextArrow.classList.toggle(cache.hHiddenClass, this.custom.carouselHTMLElement.swiper.isEnd);
}

export default SwiperHoverable;
